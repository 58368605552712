/* 一级菜单 isDown 属性是索引到二级菜单的 ID */
// 保证path 值和游戏文件名一样
//enName 保证和游戏 API返回的一样
export const menuBarData = [
    {
        key: "Sportsbook",
        expression: "体育",
        isDown: 0,
        enName:"Sportsbook",
        path:"Sportsbook"
    },
    {
        key: "Esports",
        expression: "电竞",
        isDown: 1,
        enName:"Esports",
        path: "Esports",
    },
    {
        key: "InstantGames",
        expression: "小游戏",
        isDown: 2,
        enName:"InstantGames",
        path:"InstantGames",
    },
    {
        key: "Casino",
        expression: "真人",
        isDown: 3,
        enName:"LiveCasino",
        path:"LiveCasino"
    },
    {
        key: "P2P",
        expression: "棋牌",
        isDown: 4,
        enName:"P2P",
        path:"P2P",
    },
    {
        key: "Slot",
        expression: "电子",
        isDown: 5,
        enName:"Slot",
        path:"Slot"
    },
    {
        key: "Lottery",
        expression: "彩票",
        isDown: 6,
        enName:"Lottery",
        path:"Lottery"
    },
];
export const menuRightBarData = [
    // {
    // 	key: 'vip',
    // 	expression: 'VIP'
    // },
    {
        key: "Promotions",
        expression: "优惠",
    },
    {
        key: "DailyGift",
        expression: "每日好礼",
    },
    {
        key: "Shop8",
        expression: "天王俱乐部",
    },
    {
        key: "More",
        expression: "更多",
        isDown: 7,
    },
    {
        key: "Alliance",
        expression: "联盟",
    },
];
/* 二级菜单  0: 体育 1: 电竞 2: 真人 3: 棋牌 4: 电子 7: 彩票 8: 更多 */
export const hoverSecondary = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [
        {
            title: "USDT 介绍",
            providerImageUrl: "/cn/img/home/header/Others_USDT.png",
            provider: "/USDTintroduce",
            gameId: "",
            type: "static",
            kind: "new",
        },
        {
            title: "推荐好友",
            providerImageUrl: "/cn/img/home/header/Others_ReferAFriend.png",
            provider: "/Refer",
            gameId: "76",
            type: "static",
        },
        {
            title: "钻石俱乐部",
            providerImageUrl: "/cn/img/home/header/Others_VIP.png",
            provider: "/DiamondClub",
            gameId: "",
            type: "static",
            kind: "hot",
        },
        {
            title: "赞助伙伴",
            providerImageUrl: "/cn/img/home/header/Others_SponsorshipPage.png",
            provider: "/Sponsor",
            gameId: "",
            type: "static",
            kind: "hot",
        },

        {
            title: "下载中心",
            providerImageUrl: "/cn/img/home/header/Others_Download.png",
            provider: "/Download",
            gameId: "76",
            type: "static",
        },
        {
            title: "自我限制​",
            providerImageUrl:
                "/cn/img/home/header/Others_BehavioralRestrictions.png",
            provider: "/Selfexclusion",
            gameId: "76",
            type: "static",
        },
    ],
];
export const hoverSecondaryDetail = [
    {
        type: "sport",
        chinese: "体育投注",
        english: "SPORT BETTING",
        promotion: "天天享超高返水",
        promotionVal: "1.2%",
        gameContent:
            "提供最及时的体育资讯、最实用的赛事分析技巧以及最靠谱的体育平台！",
        gameAddress: "/Sportsbook",
        type2024: "Sportsbook",
    },
    {
        type: "imsport",
        chinese: "电子竞技",
        english: "eSPORT BETTING",
        promotion: "天天享超高返水",
        promotionVal: "1.2%",
        gameContent: "专业的电竞平台，为电竞玩家们带来各种多样化的游戏体验！",
        gameAddress: "/Esports",
        type2024: "Esports",
    },
    {
        type: "instantGames",
        chinese: "小游戏",
        english: "SP GAMING",
        promotion: "日返水返还",
        promotionVal: "0.58%",
        gameContent: "专业的电竞平台，为电竞玩家们带来各种多样化的游戏体验！",
        gameAddress: "/InstantGames",
        type2024: "InstantGames",
    },

    {
        type: "casino",
        chinese: "真人娱乐",
        english: "LIVE CASINO",
        promotion: "天天享超高返水",
        promotionVal: "1.2%",
        gameContent: "乐殿堂线上真人娱乐场，美女主播、性感荷官现场互动！",
        gameAddress: "/LiveCasino",
        type2024: "LiveCasino",
    },
    {
        type: "chess",
        chinese: "棋牌游戏",
        english: "P2P GAMING",
        promotion: "天天享超高返水",
        promotionVal: "1.2%",
        gameContent: "玩家互动，真实对战，拥有多款火爆热门的棋牌游戏！",
        gameAddress: "/P2P",
        type2024: "P2P",
    },
    {
        type: "slotgame",
        chinese: "电子游戏",
        english: "SLOT GAMING",
        promotion: "天天享超高返水",
        promotionVal: "1.2%",
        gameContent:
            "最火热的电子游戏平台，提供超多款电子游戏类型，丰厚奖金池回馈！",
        gameAddress: "/Slot",
        type2024: "Slot",
    },
    {
        type: "lottery",
        chinese: "彩票游戏",
        english: "LOTTERY",
        promotion: "天天享超高返水",
        promotionVal: "1.2%",
        gameContent: "包含香港六合彩，急速时时彩，北京赛车彩等！",
        gameAddress: "/Lottery",
        type2024: "KenoLottery",
    },

    {
        type: "more",
        chinese: "更多精彩",
        english: "MORE FEATURES",
        promotion: "",
        promotionVal: "",
        gameContent: "",
        qrcodeImg: "", // 二维码图片地址
        qrcodeAddress: "www.tlc880109.com",
    },
];

