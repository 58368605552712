import { sendPiwikEvent, sendPiwikEventWithEventValue } from './PiwikUtil'

export const sendProductGamePiwikEvent4Home = {
    run2dot1: (GameCategory) => {
        sendPiwikEvent('Home_TopNav', `Go to ${GameCategory} Lobby`, `Home_TopNav_C_${GameCategory}`)
    },
    run3: (providerCode, GameCategory) => {
        let lower = typeof GameCategory==='string' ? GameCategory.toLowerCase() : '';
        if (['sportsbook', 'esports', 'kenolottery'].includes(lower)) {
            sendPiwikEvent('Home', `Launch ${providerCode}`, `Home_${GameCategory}_C_${providerCode}`)
        } else if (typeof providerCode === 'string' && typeof GameCategory === 'string' && providerCode.toLowerCase()==='aviator' && lower.includes('instantgame')) {
            let gameName = 'Aviator';
            sendPiwikEventWithEventValue('Home', `Launch HotGame ${gameName}`, `Home_InstantGames_C_HotGame`, '', [
                {customVariableKey: 'Home_InstantGames_C_HotGame_GameName', customVariableValue: gameName}
            ])
        } else {
            sendPiwikEvent('Home', `Go to ${providerCode} Lobby`, `Home_${GameCategory}_C_${providerCode}`)
        }
    },
    run4: (GameCategory) => {
        sendPiwikEvent('Home', `View ${GameCategory} Listing`, `Home_C_${GameCategory}`)
    },
    run6: (Title, PositionNumber) => {
        sendPiwikEventWithEventValue('Home', `Click Banner`, `Home_C_Banner`, '', [
                {customVariableKey:'Home_C_Banner_Title', customVariableValue: Title /* Ex: NBA..*/ },
                {customVariableKey:'Home_C_Banner_Position', customVariableValue: PositionNumber /* ex: 0, 2311*/}
            ])
    },
}
export const sendProductGamePiwikEvent4VendorLobby = {
    run7: (GameCategory, providerCode) => {
        sendPiwikEvent(`${GameCategory}_Listing`, `Launch ${providerCode}`, `${GameCategory}_Listing_C_${providerCode}`)
    },
    run9: (GameCategory, providerCode) => {
        sendPiwikEvent(`${GameCategory}_Listing`, `Launch ${providerCode}`, `${GameCategory}_Listing_C_${providerCode}_Comercial`)
    },
}
export const sendProductGamePiwikEvent4GameList = {
    run1: (GameCategory) => {
        sendPiwikEvent(`${GameCategory}_Listing`, `Go to Recommended Game Listing`, `${GameCategory}_Listing_C_Recommended_More`)
    },
    run2: (GameCategory, GameName, providerCode) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Listing`, `Launch Game ${GameName}`, `${GameCategory}_Listing_C_Recommended_${providerCode}_Game`, '', [{
            customVariableKey:`${GameCategory}_Listing_C_Recommended_${providerCode}_GameName`,
            customVariableValue: GameName /* GameName, ex: 三重现金大爆炸… */ }])
    },
    run3: (GameCategory) => { // GameCategory only be Slot(?)
        sendPiwikEvent(`${GameCategory}_Listing`, `Go to Jackpot Game Listing`, `${GameCategory}_Listing_C_Jackpot_More`)
    },
    run4: (GameCategory, providerCode, GameName) => { // GameCategory only be Slot(?)
        sendPiwikEventWithEventValue(`${GameCategory}_Listing`, `Launch Game ${GameName}`, `${GameCategory}_Listing_C_${providerCode}_Jackpot_Game`, '', [{
            customVariableKey:`${GameCategory}_Listing_C_${providerCode}_Jackpot_GameName`,
            customVariableValue: GameName /* GameName, ex: 大轮盘, Megaway … */}])
    },
    run5: (GameCategory, providerCode) => {
        sendPiwikEvent(`${GameCategory}_Listing`, `Go to ${providerCode} Lobby`, `${GameCategory}_Listing_C_${providerCode}`)
    },
    run6: (GameCategory, CategoryName) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Lobby`, `Filter Game`, `${GameCategory}_Lobby_C_GameType`, '', [{
            customVariableKey:`${GameCategory}_Lobby_C_GameType`,
            customVariableValue: CategoryName /* ex: 捕鱼游戏… */}])
    },
}
export const sendProductGamePiwikEvent4GameLobby = {
    run1: (GameCategory) => {
        sendPiwikEvent(`${GameCategory}_Lobby`, `Search Game`, `${GameCategory}_Lobby_C_Search`)
    },
    run2: (GameCategory, SortingType) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Lobby`, `Filter Game`, `${GameCategory}_Lobby_C_Sorting`, '', [{
            customVariableKey:`${GameCategory}_Lobby_C_SortingType`,
            customVariableValue: SortingType /* Default, IsNew, Recommended, AtoZ */}])
    },
    run3: (GameCategory, providerCode, GameName) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Lobby`, `Launch Game ${GameName}`, `${GameCategory}_Lobby_C_${providerCode}_Game`, '', [{
            customVariableKey:`${GameCategory}_Lobby_C_${providerCode}_GameName`,
            customVariableValue: GameName /* ex: 大轮盘, JiliFishing, …*/}])
    },
    run4: (GameCategory, ProviderCode) => {
        sendPiwikEvent(`${GameCategory}_Lobby`, `Filter Vendor`, `${GameCategory}_Lobby_C_${ProviderCode}`)
    },
    run5: (GameCategory, CategoryName) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Lobby`, `Filter Game`, `${GameCategory}_Lobby_C_GameType`, '', [{
            customVariableKey:`${GameCategory}_Lobby_C_GameType`,
            customVariableValue: CategoryName /* ex: 捕鱼游戏… */}])
    },
    run6: (GameCategory, CategoryName) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Lobby`, `Filter Game`, `${GameCategory}_Lobby_C_GameFeature`, '', [{
            customVariableKey:`${GameCategory}_Lobby_C_GameFeature`,
            customVariableValue: CategoryName /* ex: 免费旋转,Megaways… */}])
    },
    run7: (GameCategory, CategoryName) => {
        sendPiwikEventWithEventValue(`${GameCategory}_Lobby`, `Filter Game`, `${GameCategory}_Lobby_C_GamePayline`, '', [{
            customVariableKey:`${GameCategory}_Lobby_C_GamePayline`,
            customVariableValue: CategoryName /* 5-10 */}])
    },
}
