import { get, put, patch } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { realyNameReg } from "$ACTIONS/reg";
import { message } from "antd";
import { setGameHideAction } from "$STORE/thunk/gameThunk";
import { checkIsRemoveShaba } from "$ACTIONS/util";
import store from "../store/store";
// 获取用户账户信息以及设置为LocalStorage
export function getMemberInfo(call, refresh) {
    const localMemberInfo = localStorage.getItem("memberInfo");
    let memberInfoIsLoad = [0, 0];
    let memberInfo = {};

    if (localMemberInfo === null || localMemberInfo === "" || refresh) {
        get(ApiPort.GETMemberlistAPI)
            .then((res) => {
                localStorage.setItem(
                    "memberCode",
                    res.result.memberInfo.memberCode
                );
                memberInfoIsLoad.splice(0, 1, 1);

                if (res && res.isSuccess) {
                    res.result.memberInfo.contacts.forEach((val) => {
                        res.result.memberInfo["isVerified" + val.contactType] =
                            [val.contact, val.status === "Verified"];
                    });

                    let {
                        documentID,
                        dob,
                        address,
                        isVerifiedEmail,
                        isVerifiedPhone,
                    } = res.result.memberInfo;
                    if (res?.result?.memberInfo && Object.prototype.hasOwnProperty.call(res.result.memberInfo, 'identityCard')) {
                        documentID = res.result.memberInfo.identityCard;
                    }
                    /* 提款验证步骤 */
                    if (!documentID || documentID === "") {
                        /* 第1步 身份证  */
                        res.result.memberInfo.withdrawalVerifyStep = 1;
                    } else if (!dob || address.city == "") {
                        /* 第2步 生日 地址 */
                        res.result.memberInfo.withdrawalVerifyStep = 2;
                    } else if (isVerifiedPhone && !isVerifiedPhone[1]) {
                        /* 第3步 手机验证 */
                        res.result.memberInfo.withdrawalVerifyStep = 3;
                    } else if (isVerifiedEmail && !isVerifiedEmail[1]) {
                        /* 第4步 邮箱验证 */
                        res.result.memberInfo.withdrawalVerifyStep = 4;
                    } else {
                        /* 提款验证完成 */
                        res.result.memberInfo.withdrawalVerifyStep = false;
                    }

                    Object.assign(
                        memberInfo,
                        res.result.memberInfo,
                        res.result.memberNewInfo
                    );
                    console.log("------------------>", memberInfo);
                }
                const memberInfoString = JSON.stringify(memberInfo);
                localStorage.setItem(
                    "memberInfo",
                    memberInfoString === "{}" ? "" : memberInfoString
                );

                if (typeof _paq === "object") {
                    _paq.push(["setUserId", memberInfo.memberCode]);
                }

                if (checkIsRemoveShaba(memberInfo.registerDate)) {
                    const updateHide = setGameHideAction("OWS");
                    store.dispatch(updateHide);
                }

                //首选钱包
                localStorage.setItem('PreferWallet', memberInfo.preferWallet);

                call(memberInfo);
            })
            .catch((error) => {
                console.log("getMemberInfo(GETMemberlistAPI) error:", error);
                return null;
            });
    } else {
        call(JSON.parse(localMemberInfo));
    }
}

// 设置用户真实姓名
export function setUserRealyName(name, call) {
    if (name === "") {
        return call(message.error("请输入真实名字。"));
    }
    if (!realyNameReg.test(name)) {
        return call(message.error("请输入正确格式的名字。"));
    }

    patch(ApiPort.PATCHMemberlistAPI, {
        key: "FirstName",
        value1: name,
    })
        .then((res) => {
            if (res) {
                if (res.isSuccess == true) {
                    message.success("更新成功!");
                } else if (res.isSuccess == false) {
                    message.error(res.result.Message);
                }
                call(res);
            }
        })
        .catch((error) => {
            console.log("setUserRealyName error:", error);
            return null;
        });
}

export function setMemberInfo(data, call, moreConf) {
    patch(ApiPort.PUTMemberlistAPI, data, moreConf)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("setMemberInfo error:", error);
            return null;
        });
}

// 获取密保问题
export function getQuestion(call) {
    get(ApiPort.GetQuestions)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("getQuestion error:", error);
            return null;
        });
}

// get(GETMemberlistAPI).then((res) => {
//   if (res) {
//       this.setState({
//           ThisMemberData: res,
//       })
//       if (res.result.memberInfo.firstName == '' && this.state.WCLBToNext == false) {
//           this.setState({
//               ThisVisible: true,
//               depositName: res.result.memberInfo.firstName,
//           })

//       } else {
//           let depositName = this.state.LBStatic ? JSON.parse(localStorage.getItem('LBtransfer')).val.depositName : res.result.memberInfo.firstName
//           this.setState({
//               ThisVisible: false,
//               depositName
//           })
//       }
//       localStorage.setItem('PreferWallet', JSON.stringify(res.result.memberInfo.preferWallet));
//   }
// })
//   .catch(error => {
//   });

/**
 * @description: 提交地址 生日 资料 提款验证
 * @param {*} userInfo
 * @param {*} call
 * @return {*}
 */
export function setMemberInfoPut(data, call) {
    put(ApiPort.PUTMemberlistAPI, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("setMemberInfo error:", error);
            return null;
        });
}
