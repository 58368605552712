/*
 * @Author: Alan
 * @Date: 2023-05-09 18:01:54
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-04 22:29:14
 * @Description: 优惠请求
 * @FilePath: /F1-M1-WEB-Code/actions/promotionRequest.js
 */
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import moment from "moment";
import { PromotionList, GetAppliedHistory, GetRebateList } from '../actions/cmsApi'
import { message } from 'antd';

// 取得免費優惠資料
export async function getFreePromotions() {
    let params = {
        type: 'free'
        // transactionType: '',
        // wallet: ''
    };
    return PromotionList(params) // 返回一個 Promise
        .then((res)=>{
            console.log('PromotionFreeList =======================>',res)
            if (res){
                const freebetWithBonus = Array.isArray(res) ? res.filter(v => v.bonusData) : [];
                return freebetWithBonus
            }
        });
}

// 取得已申請優惠資料
export async function getAppliedHistory() {
    let params = {
        startDate: moment(new Date())
            .subtract(90, 'd')
            .startOf('day')
            .utcOffset(8)
            .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(new Date()).utcOffset(8).format('YYYY-MM-DDTHH:mm:ss')
    };
    return GetAppliedHistory(params).then((res) => {
        console.log('GetAppliedHistory res ========>', res)
        if (res){
            return res
        }
    })
}

// 取得Promotions資料 （加入本地存储 优化加载速度体验）
export async function getPromotionList() {
    let params = {
        type: 'general',
        transactionType: '',
        wallet: ''
    };
    return PromotionList(params) // 返回一個 Promise
        .then((res)=>{
            console.log('PromotionList resssss ======================= >',res)
            if (res){
                return res
            }
        });
}

/**
 * @description: 获取优惠的菜单分类 （加入本地存储 优化加载速度体验）
 * @return {*}
 */
export async function getPromotionCategories() {
    const isSupportWebp = global.isSupportWebp ? "?displaying_webp" : "";

    let Categories =
        JSON.parse(localStorage.getItem("PromotionCategories")) || [];

    const res = await get(ApiPort.PromotionCategories + isSupportWebp);

    if (res && res.length != 0) {
        Categories = res || [];

        localStorage.setItem("PromotionCategories", JSON.stringify(Categories));
    }

    return Categories;
}

//取得反水資料
export async function getRebateData(
    startTime = moment(new Date()).format("YYYY-MM-DD"),
    endTime = moment(new Date()).format("YYYY-MM-DD"),
) {
    let params = {
        startDate: startTime,
        endDate: endTime
    };
    console.log('GetRebateList params !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ', params)
    return GetRebateList(params).then((res)=>{
        console.log('GetRebateList res !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ', res)
        if(res){
            return res.result
        }
    })
}
